"use strict";

//
// Select2 Initialization
//

$.fn.select2.defaults.set("theme", "bootstrap5");
$.fn.select2.defaults.set("width", "100%");
$.fn.select2.defaults.set("selectionCssClass", ":all:");
$.fn.select2.defaults.set("language", {
        "errorLoading": function () {
            return 'Результат не может быть загружен.';
        },
        "inputTooShort": function (args) {
            const remainingChars = args.minimum - args.input.length;

            return 'Пожалуйста, введите ' + remainingChars + ' или более символов';
        },
        "loadingMore": function () {
            return 'Загружаем ещё…';
        },
        "searching": function () {
            return 'Поиск…';
        },
        "noResults": function () {
            return "Ничего не найдено";
        }
    }
);
